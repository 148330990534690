import {
  Button,
  Group,
  Paper,
  SimpleGrid,
  Space,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core"
import { useLocalStorage } from "@mantine/hooks"
import { RiCloseLine } from "react-icons/ri"
import { Link } from "react-router-dom"

const data = [
  {
    title: "Payment Requests",
    body: `Efficiently track payments with flexibility on your dashboard of payment requests. Dive into detailed transaction insights to gain a comprehensive understanding of each real estate payment. Collect earnest money by selecting Request Payment.`,
    link: "/requests",
  },
  {
    title: "Escrow Holders",
    body: `Search, add, and easily locate your preferred escrow holders. If your desired escrow holder isn't listed within the Earnnest network, seamlessly invite them directly through our web-app.`,
    link: "/escrows",
  },
  {
    title: "Profile",
    body: `Effortlessly oversee and adjust your profile and preferences, ensuring notifications are sent to the appropriate account.`,
    link: "/profile",
  },
]

export default function AgentOnboardingCard() {
  const theme = useMantineTheme()

  const [visible, setVisible] = useLocalStorage({
    key: "agent-onboarding-card-visible",
    defaultValue: true,
  })

  if (!visible) {
    return null
  }

  return (
    <Paper
      mb="xl"
      p="xl"
      radius="md"
      sx={{
        background: theme.fn.gradient({
          from: theme.fn.themeColor("dark.5"),
          to: theme.fn.themeColor("dark.4"),
        }),
      }}>
      <Group position="apart" align="flex-start">
        <Title order={3} color="white">
          Welcome to Your Web App Dashboard
        </Title>
        <Button
          size="sm"
          variant="white"
          rightIcon={<RiCloseLine />}
          onClick={() => setVisible(false)}>
          Hide
        </Button>
      </Group>
      <Space h="md" />
      <Text color="white">
        Earnnest’s Web App has been updated to provide a comprehensive view of
        your real estate payments! Key additions for tracking and enhancing your
        payment processes include:
      </Text>
      <Space h="xl" />
      <SimpleGrid
        cols={3}
        spacing="lg"
        breakpoints={[
          { maxWidth: theme.breakpoints.lg, cols: 3, spacing: "md" },
          { maxWidth: theme.breakpoints.md, cols: 2, spacing: "sm" },
        ]}>
        {data.map((x) => (
          <Stack key={x.title} spacing="xs">
            <Title order={5} color="white">
              {x.title}
            </Title>
            <Text size="sm" color="white">
              {x.body}
            </Text>
            <Link
              to={x.link}
              style={{ textDecoration: "none", marginTop: "auto" }}>
              <Text color="white" size="xs" weight="bold" transform="uppercase">
                View {x.title} →
              </Text>
            </Link>
          </Stack>
        ))}
      </SimpleGrid>
    </Paper>
  )
}

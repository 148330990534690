import { useUserQuery } from "@earnnest-e2-frontend/platform-api/src/graphql"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import {
  Button,
  Group,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { notifications } from "@mantine/notifications"
import * as yup from "yup"
import { State } from "country-state-city"
import { useState } from "react"

const states = State.getStatesOfCountry("US")

export default function InviteEscrowHolderForm({
  name,
  onSubmitSuccess,
}: {
  name?: string
  onSubmitSuccess?: () => void
}) {
  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  const [submitting, setSubmitting] = useState(false)

  const form = useForm({
    initialValues: {
      escrowContactFirstName: "",
      escrowContactLastName: "",
      escrowContactEmail: "",
      escrowBusinessName: name || "",
      operatingRegion: "",
    },
    validate: yupResolver(
      yup.object({
        escrowContactFirstName: yup.string().required("Required"),
        escrowContactLastName: yup.string().required("Required"),
        escrowContactEmail: yup.string().email().required("Required"),
        escrowBusinessName: yup.string().required("Required"),
        escrowOperatingRegion: yup.string().required("Required"),
      }),
    ),
  })

  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        try {
          if (!user) {
            throw new Error("Could not load user. Please try again.")
          }
          setSubmitting(true)
          const endpoint = process.env.REACT_APP_SF_FORM_ENDPOINT as string
          const response = await fetch(endpoint, {
            method: "post",
            body: JSON.stringify({
              form: "invite",
              fields: {
                EscrowContactFirstName: values.escrowContactFirstName,
                EscrowContactLastName: values.escrowContactLastName,
                EscrowContactEmail: values.escrowContactEmail,
                EscrowBusinessName: values.escrowBusinessName,
                InvitedByState: values.escrowOperatingRegion,
                InvitedByFirstName: user.firstName,
                InvitedByLastName: user.lastName,
                InvitedByEmail: user.email,
              },
            }),
          })
          if (response.status !== 200) {
            throw new Error("An unexpected error occurred. Please try again.")
          }
          notifications.show({
            color: "green",
            title: "Success",
            message: "Your invite has been sent.",
          })
          onSubmitSuccess?.()
        } catch (error) {
          notifications.show({
            color: "red",
            title: "Error",
            message: error.message,
          })
        } finally {
          setSubmitting(false)
        }
      })}>
      <Title size="h2" order={2}>
        Invite Escrow Holder
      </Title>
      <Text>
        Connect an escrow holder with Earnnest. Once they’re registered you’re
        ready to go.
      </Text>
      <Stack>
        <Space h="xl" />
        <Title order={5}>Who’s your escrow holder?</Title>
        <Group grow align="start">
          <TextInput
            label="First name"
            {...form.getInputProps("escrowContactFirstName")}
          />
          <TextInput
            label="Last name"
            {...form.getInputProps("escrowContactLastName")}
          />
        </Group>
        <TextInput
          label="Escrow contact email"
          {...form.getInputProps("escrowContactEmail")}
        />
        <TextInput
          label="Business name"
          {...form.getInputProps("escrowBusinessName")}
        />
        <Select
          label="Operating Region"
          {...form.getInputProps("escrowOperatingRegion")}
          data={states.map((x) => ({
            label: x.name,
            value: x.isoCode,
          }))}
        />
      </Stack>
      <PanelFooter>
        <Button loading={submitting} size="lg" type="submit">
          Send Invite
        </Button>
      </PanelFooter>
    </form>
  )
}

import { EscrowAccountFragment } from "@earnnest-e2-frontend/platform-api/src/graphql"
import { MetaRow } from "@earnnest-e2-frontend/platform-ui/src/mantine"
import AmountText from "@earnnest-e2-frontend/platform-ui/src/mantine/AmountText"
import { Badge, Group, Space, Title } from "@mantine/core"
import { Link, useLocation } from "react-router-dom"

export default function EscrowAccountDetails({
  escrowAccount,
  viewerRole = "agent",
}: {
  escrowAccount: EscrowAccountFragment
  viewerRole: "superuser" | "admin" | "agent"
}) {
  const location = useLocation()

  const fundingSource = escrowAccount.fundingSource

  return (
    <>
      <Space h="xl" />
      <Title size="h4" order={4}>
        Escrow Account
      </Title>
      <MetaRow label="Name">{escrowAccount.name}</MetaRow>
      {viewerRole === "superuser" ? (
        <MetaRow label="Dwolla FS ID">
          {fundingSource.fundingRef || "None"}
        </MetaRow>
      ) : null}
      <MetaRow label="Operating Regions">
        <Group spacing={4}>
          {escrowAccount.operatingRegions?.map((x) => (
            <Badge color="gray">{x}</Badge>
          ))}
        </Group>
      </MetaRow>
      {escrowAccount.maxAmount ? (
        <MetaRow label="Max Amount">
          <AmountText amount={escrowAccount.maxAmount} />
        </MetaRow>
      ) : null}
      {viewerRole === "agent" ? null : (
        <MetaRow label="Gated Payments">
          {escrowAccount.gated ? "Yes" : "No"}
        </MetaRow>
      )}
      {viewerRole === "agent" ? null : (
        <MetaRow label="Approvals Required">
          {escrowAccount.approvalsRequired ? "Yes" : "No"}
        </MetaRow>
      )}
      <MetaRow label="Active">{escrowAccount.active ? "Yes" : "No"}</MetaRow>
      {viewerRole.match(/superuser|admin/) ? (
        <MetaRow label="Emails">{escrowAccount.emails?.join(", ")}</MetaRow>
      ) : null}
      {viewerRole === "superuser" ? (
        <>
          <Space h="xl" />
          <Title order={4}>Relationships</Title>
          {escrowAccount.createdBy ? (
            <MetaRow label="Creating User">
              <Link
                to={{
                  pathname: `/users/${escrowAccount.createdBy.id}`,
                  state: location.state,
                  search: location.search,
                }}>
                {escrowAccount.createdBy.fullName}
              </Link>
            </MetaRow>
          ) : null}
          {escrowAccount.organization ? (
            <MetaRow label="Organization">
              <Link
                to={{
                  pathname: `/organizations/${escrowAccount.organization.id}`,
                  state: location.state,
                  search: location.search,
                }}>
                {escrowAccount.organization.name}
              </Link>
            </MetaRow>
          ) : null}
          <MetaRow label="Payments">
            <Link
              to={{
                pathname: `/transactions`,
                search: `?escrow_account_id=${escrowAccount.id}`,
              }}>
              Associated payments
            </Link>
          </MetaRow>
          {escrowAccount.fundingSource ? (
            <MetaRow label="Funding Source">
              <Link
                to={{
                  pathname: `/funding-sources/${fundingSource?.id}`,
                  state: location.state,
                  search: location.search,
                }}>
                {escrowAccount.fundingSource.name}
              </Link>
            </MetaRow>
          ) : null}
        </>
      ) : null}
    </>
  )
}

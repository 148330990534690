import { useTransactionTemplateQuery } from "@earnnest-e2-frontend/platform-api/src/graphql"
import CreateEMDRequestForm from "@earnnest-e2-frontend/platform-views/src/mantine/CreateEMDRequestForm"
import ManageTransactionForm from "@earnnest-e2-frontend/platform-views/src/mantine/ManageTransactionForm"
import TransactionDetails from "@earnnest-e2-frontend/platform-views/src/mantine/TransactionDetails"
import TransactionTimeline from "@earnnest-e2-frontend/platform-views/src/mantine/TransactionTimeline"
import { Center, Loader, Space, Tabs, Text, Title } from "@mantine/core"
import { useHistory, useLocation, useParams } from "react-router-dom"

export default function TransactionPanel() {
  const params = useParams()
  const history = useHistory()
  const location = useLocation()

  const activeTab =
    new URLSearchParams(location.search).get("panelTab") || "details"

  const transactionTemplateQuery = useTransactionTemplateQuery({
    skip: !params.transactionTemplateId,
    variables: {
      id: params.transactionTemplateId,
    },
  })
  const transactionTemplate = transactionTemplateQuery.data?.transactionTemplate

  if (transactionTemplateQuery.loading) {
    return (
      <Center mih="70vh">
        <Loader />
      </Center>
    )
  }

  if (
    transactionTemplateQuery.error ||
    !params.transactionTemplateId ||
    !transactionTemplate
  ) {
    return (
      <Text color="red">
        {!params.transactionTemplateId
          ? "Route missing transaction template ID"
          : transactionTemplateQuery.error
          ? transactionTemplateQuery.error.message
          : "Error loading transaction"}
      </Text>
    )
  }

  if (!transactionTemplate.receiverEmail) {
    return <CreateEMDRequestForm transactionTemplate={transactionTemplate} />
  }

  return (
    <>
      <Title size="h2" order={2}>
        Payment Request #{transactionTemplate.id}
      </Title>
      <Space h="xs" />
      <Tabs
        value={activeTab}
        onTabChange={(tab) => {
          const searchParams = new URLSearchParams(location.search)
          searchParams.set("panelTab", tab)
          history.push({
            pathname: location.pathname,
            state: location.state,
            search: searchParams.toString(),
          })
        }}>
        <Tabs.List>
          <Tabs.Tab value="details">Details</Tabs.Tab>
          <Tabs.Tab value="events">Events</Tabs.Tab>
          <Tabs.Tab value="manage">Manage</Tabs.Tab>
        </Tabs.List>
        <Space h="xl" />
        <Tabs.Panel value="details">
          <TransactionDetails
            transactionTemplate={transactionTemplate}
            viewerRole="agent"
          />
        </Tabs.Panel>
        <Tabs.Panel value="events">
          <TransactionTimeline transactionTemplate={transactionTemplate} />
        </Tabs.Panel>
        <Tabs.Panel value="manage">
          <ManageTransactionForm
            transactionTemplate={transactionTemplate}
            viewerRole="agent"
          />
        </Tabs.Panel>
      </Tabs>
    </>
  )
}

import { useEscrowAccountsListQuery } from "@earnnest-e2-frontend/platform-api/src/graphql"
import EscrowAccountsTable from "@earnnest-e2-frontend/platform-views/src/mantine/EscrowAccountsTable"
import { Space, Stack, Text, TextInput } from "@mantine/core"
import { RiBankFill, RiSearchLine } from "react-icons/ri"
import { useHistory, useLocation } from "react-router-dom"

export default function EscrowsHoldersPage() {
  const location = useLocation()
  const history = useHistory()

  const searchParams = new URLSearchParams(location.search)
  const query = searchParams.get("query")

  const escrowsQuery = useEscrowAccountsListQuery({
    notifyOnNetworkStatusChange: true, // so loading status updates for pagination
    variables: {
      limit: 12,
      lookup: {
        name: query || undefined,
      },
    },
    skip: !query,
  })

  return (
    <>
      <TextInput
        type="search"
        defaultValue={query}
        icon={<RiSearchLine />}
        placeholder="Search escrow holders..."
        onChange={(e) => {
          const searchParams = new URLSearchParams(location.search)
          searchParams.set("query", e.currentTarget.value)
          history.replace({
            pathname: location.pathname,
            state: location.state,
            search: searchParams.toString(),
          })
        }}
      />
      <Space h="lg" />
      {query ? (
        <EscrowAccountsTable
          query={escrowsQuery}
          viewerRole="agent"
          getInviteLink={() => ({
            pathname: `/escrows/invite`,
            search: location.search,
            state: location.state,
          })}
        />
      ) : (
        <Stack align="center" justify="center" mih="70vh">
          <RiBankFill color="white" size={48} />
          <Text>Find or invite your escrow holders.</Text>
        </Stack>
      )}
    </>
  )
}

/** @jsxRuntime classic */
import { EarnnestAPIProvider } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAPI"
import { EarnnestAnalyticsProvider } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { EarnnestAuth0Provider } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import { EarnnestErrorBoundary } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestErrors"
import { ToastProvider } from "@earnnest-e2-frontend/platform-ui/src/Toast"
import { ThemeProvider } from "@earnnest-e2-frontend/platform-ui/src/UI/Theme"
import { MantineProviders } from "@earnnest-e2-frontend/platform-ui/src/mantine"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import ReactDOM from "react-dom"
import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

ReactDOM.render(
  <Router>
    <MantineProviders>
      <ThemeProvider activeScheme="dark">
        <EarnnestErrorBoundary>
          <EarnnestAnalyticsProvider>
            <EarnnestAuth0Provider>
              <EarnnestAPIProvider>
                <ToastProvider>
                  <App />
                </ToastProvider>
              </EarnnestAPIProvider>
            </EarnnestAuth0Provider>
          </EarnnestAnalyticsProvider>
        </EarnnestErrorBoundary>
      </ThemeProvider>
    </MantineProviders>
  </Router>,
  document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

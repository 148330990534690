import { useEscrowAccountQuery } from "@earnnest-e2-frontend/platform-api/src/graphql"
import EscrowAccountDetails from "@earnnest-e2-frontend/platform-views/src/mantine/EscrowAccountDetails"
import { Center, Loader, Space, Text, Title } from "@mantine/core"
import { useParams } from "react-router-dom"

export default function TransactionPanel() {
  const params = useParams()

  const escrowAccountQuery = useEscrowAccountQuery({
    skip: !params.escrowAccountId,
    variables: {
      id: params.escrowAccountId,
    },
  })
  const escrowAccount = escrowAccountQuery.data?.escrowAccount

  if (escrowAccountQuery.loading) {
    return (
      <Center mih="70vh">
        <Loader />
      </Center>
    )
  }

  if (escrowAccountQuery.error || !escrowAccount) {
    if (escrowAccountQuery.error || !params.escrowAccountId || !escrowAccount) {
      return (
        <Text color="red">
          {!params.escrowAccountId
            ? "Route missing escrow account ID"
            : escrowAccountQuery.error
            ? escrowAccountQuery.error.message
            : "Error loading escrow account"}
        </Text>
      )
    }
  }

  return (
    <>
      <Title size="h2" order={2}>
        {escrowAccount.name}
      </Title>
      <Space h="xs" />
      <EscrowAccountDetails escrowAccount={escrowAccount} viewerRole="agent" />
    </>
  )
}

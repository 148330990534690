import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { Box, Text } from "@earnnest-e2-frontend/platform-ui/src/UI"
import React, { useEffect } from "react"

interface ErrorPageProps {
  errorCode?: string | null
  errorMessage?: string | null
}

export default function ErrorPage({ errorCode, errorMessage }: ErrorPageProps) {
  const { track } = useEarnnestAnalytics()

  useEffect(() => {
    if (errorCode) {
      track(`${errorCode} Error Viewed`)
    } else {
      track("Error Viewed")
    }
  }, [track, errorCode])

  return (
    <Box
      bg="contrast0"
      style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Text type="heading3" color="contrast100">
        Error {errorCode}
      </Text>
      <Box h={12} />
      <Text type="baseText" color="contrast100">
        {errorMessage || "An unexpected error occurred."}
      </Text>
      <Box h={24} />
    </Box>
  )
}

import { useUserQuery } from "@earnnest-e2-frontend/platform-api/src/graphql"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import { Box } from "@mantine/core"
import UserProfileForm from "@earnnest-e2-frontend/platform-views/src/mantine/UserProfileForm"

export default function UserProfilePage() {
  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  if (userQuery.loading || !user) {
    return <LoadingOverlay />
  }

  return (
    <Box maw={600}>
      <UserProfileForm user={user} />
    </Box>
  )
}
